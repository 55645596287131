/**
 * Define your API endpoints and other constants that vary according to the environment
 */

import { isProduction } from './utils/env'

const development = {
  MWM_AUTH_API_URL: 'https://dev.account.mwm-users.mwmwebapis.com',
  MWM_TECH_PLATFORM_URL: 'https://management-dev-europe-west2-anzw6up6bq-nw.a.run.app',
}

const production = {
  MWM_AUTH_API_URL: 'https://prod.account.mwm-users.mwmwebapis.com',
  MWM_TECH_PLATFORM_URL: 'https://management-prod-europe-west2-anzw6up6bq-nw.a.run.app',
}

const config = isProduction() ? production : development

export default config
