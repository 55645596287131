import type { ICodeBlock } from './types/code-block'

export const COOKIES = {
  INSTALLATION_ID: 'x-mwm-account-installation-id',
  REFRESH_TOKEN: 'x-mwm-account-refresh-token',
  TOKENS: 'x-mwm-account-portal-access-token',
  USER_ID: 'x-mwm-account-portal-user-id',
}

export const CLIENT_ID = 'mwm'

export const APP_KEY = 'tech_platform'

export const tabletBreakpoint = 767

export const CODE_BLOCKS: ICodeBlock = {
  apiKey: {
    bash: `curl "https://prod.api.mwm-tech-platform.mwmwebapis.com/..." \\
    -H "api-key: YOUR-API-KEY-HERE"`,
    go: `package main

import (
  "fmt"
  "io"
  "net/http"
)

func main() {
  url := "https://prod.api.mwm-tech-platform.mwmwebapis.com/..."
  apiKey := "YOUR-API-KEY-HERE"

  req, errRequest := http.NewRequest("GET", url, nil)
  if errRequest != nil {
    // TODO : handle error
  }

  req.Header.Add("api-key", apiKey)
  // Do request
}`,
    javascript: `const fetch = require('node-fetch');

const url = 'https://prod.api.mwm-tech-platform.mwmwebapis.com/...';
const apiKey = 'YOUR-API-KEY-HERE';

const headers = {
  'api-key': apiKey,
};

fetch(url, { method: 'GET', headers: headers })
  .then((response) => {
    // TODO : handle response
  })
  .then((data) => {
    if (data) {
      console.log(data);
    }
  })
  .catch((error) => {
    // TODO : handle error
  });`,
    kotlin: `import khttp.get

fun main() {
    val url = "https://prod.api.mwm-tech-platform.mwmwebapis.com/..."
    val apiKey = "YOUR-API-KEY-HERE"

    val headers = mapOf(
        "api-key" to apiKey
    )

    val response = get(url, headers = headers)
    // TODO : handle response
}`,
    php: `<?php

$apiUrl = "https://prod.api.mwm-tech-platform.mwmwebapis.com/...";
$apiKey = "YOUR-API-KEY-HERE";

$ch = curl_init($apiUrl);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);

// Set the HTTP headers
$headers = [
    "api-key: " . $apiKey,
];
curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);

$response = curl_exec($ch);
// TODO handle response
?>`,
    python: `import requests

url = "https://prod.api.mwm-tech-platform.mwmwebapis.com/..."
api_key = "YOUR-API-KEY-HERE"

headers = {
    "api-key": api_key
}

response = requests.get(url, headers=headers)
# TODO : handle response
`,
    swift: `import Foundation

let apiUrl = "https://prod.api.mwm-tech-platform.mwmwebapis.com/..."
let apiKey = "YOUR-API-KEY-HERE"

if let url = URL(string: apiUrl) {
    var request = URLRequest(url: url)
    request.httpMethod = "GET"

    request.addValue(apiKey, forHTTPHeaderField: "api-key")

    let task = URLSession.shared.dataTask(with: request) { (data, response, error) in
        // TODO : handle response
    }
    task.resume()
} else {
    print("Invalid URL")
}`,
  },
  getJobResult: {
    bash: `curl -X GET "https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/{job_id}" \\
-H "accept: application/json" \\
-H "api-key: YOUR-API-KEY-HERE"`,
    go: `package main

import (
    "encoding/json"
    "fmt"
    "net/http"
)

type getJobResult struct {
    ResultID string \`json:"result_id"\`
    Status   string \`json:"status"\`
    Result   struct {
        JobId   string             \`json:"job_id"\`
        JobType string             \`json:"job_type"\`
        Split   string             \`json:"split"\`
        Files   []getJobResultFile \`json:"files"\`
    } \`json:"result"\`
    Error string \`json:"error"\`
}

type getJobResultFile struct {
    ID          string \`json:"id"\`
    Type        string \`json:"type"\`
    DownloadUrl string \`json:"download_url"\`
}

type responseError struct {
    Kind    string \`json:"kind"\`
    Message string \`json:"message"\`
}

func main() {
    url := "https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/{job_id}" // Replace {job_id} with the actual job ID
    apiKey := "YOUR-API-KEY-HERE"

    req, err := http.NewRequest("GET", url, nil)
    if err != nil {
        fmt.Println("Failed to init request:", err.Error())
        return
    }

    req.Header.Add("accept", "application/json")
    req.Header.Add("api-key", apiKey)

    resp, errCall := http.DefaultClient.Do(req)
    if errCall != nil {
        fmt.Println("Failed to execute request:", err)
        return
    }

    defer resp.Body.Close()

    if resp.StatusCode != http.StatusOK {
        var respErr responseError

        if errDecode := json.NewDecoder(resp.Body).Decode(&respErr); errDecode != nil {
            fmt.Println("Failed to decode response:", errDecode)
        } else {
            fmt.Printf("Request failed with status code: %d | kind: %s | message: %s\\n", resp.StatusCode, respErr.Kind, respErr.Message)
        }

        return
    }

    var result getJobResult
    if errDecode := json.NewDecoder(resp.Body).Decode(&result); errDecode != nil {
        fmt.Println("Failed to decode response:", errDecode.Error())
        return
    }

    // Handle the response data as needed
    fmt.Println("Request was successful.")
}`,
    javascript: `const fetch = require('node-fetch');

const url = 'https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/{job_id}'; // Replace {job_id} with the actual job ID
const apiKey = 'YOUR-API-KEY-HERE';

const headers = {
  accept: 'application/json',
  'api-key': apiKey
};

fetch(url, {
  method: 'GET',
  headers: headers
})
  .then(response => {
    if (response.status === 200) {
      // Handle the response data as needed
      console.log("Request was successful.");
    } else {
      console.log(\`Request failed with status code: \${response.status}\`);
    }
  })
  .catch(error => {
    console.error('Error making the request:', error);
  });
`,
    kotlin: `import khttp.get

fun main() {
    val jobId = "YOUR-JOB-ID-HERE" // Replace with the actual job ID
    val url = "https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/$jobId"
    val apiKey = "YOUR-API-KEY-HERE"

    val headers = mapOf(
        "accept" to "application/json",
        "api-key" to apiKey
    )

    val response = get(url, headers = headers)

    if (response.statusCode == 200) {
        // Handle the response data as needed
        println("Request was successful.")
    } else {
        println("Request failed with status code: \${response.statusCode}")
    }
}`,
    php: `<?php

$jobId = 'YOUR-JOB-ID-HERE'; // Replace with the actual job ID
$apiUrl = "https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/$jobId";
$apiKey = "YOUR-API-KEY-HERE";

$ch = curl_init($apiUrl);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);

// Set the HTTP headers
$headers = [
    "accept: application/json",
    "api-key: " . $apiKey,
];
curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);

$response = curl_exec($ch);

if ($response === false) {
    echo 'cURL Error: ' . curl_error($ch);
} else {
    $httpCode = curl_getinfo($ch, CURLINFO_HTTP_CODE);

    if ($httpCode === 200) {
        // Handle the response data as needed
        echo "Request was successful.";
    } else {
        echo "Request failed with status code: $httpCode";
    }
}

curl_close($ch);
?>`,
    python: `import requests

url = "https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/{job_id}" # Replace {job_id} with the actual job ID
api_key = "YOUR-API-KEY-HERE"

headers = {
    "accept": "application/json",
    "api-key": api_key
}

response = requests.get(url, headers=headers)

if response.status_code == 200:
    # Handle the response data as needed
    print("Request was successful.")
else:
    print(f"Request failed with status code: {response.status_code}")
`,
    swift: `import Foundation

let jobId = "YOUR-JOB-ID-HERE" // Replace with the actual job ID
let apiUrl = "https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/\\(jobId)"
let apiKey = "YOUR-API-KEY-HERE"

if let url = URL(string: apiUrl) {
    var request = URLRequest(url: url)
    request.httpMethod = "GET"
    request.setValue("application/json", forHTTPHeaderField: "accept")
    request.setValue(apiKey, forHTTPHeaderField: "api-key")

    let task = URLSession.shared.dataTask(with: request) { (data, response, error) in
        if let error = error {
            print("Error making the request: \\(error)")
        } else if let httpResponse = response as? HTTPURLResponse {
            if httpResponse.statusCode == 200 {
                // Handle the response data as needed
                print("Request was successful.")
            } else {
                print("Request failed with status code: \\(httpResponse.statusCode)")
            }
        }
    }
    task.resume()
} else {
    print("Invalid URL")
}`,
  },
  job: {
    bash: `curl -X POST "https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/start" \\
-H "accept: application/json" \\
-H "content-type: application/json" \\
-H "api-key: YOUR-API-KEY-HERE" \\
-d '{ 
  "file_id":"7268b08f-323b-435b-bb07-ea924dec306d",
  "callback":"https://mycallback.com",
  "separation_type":"split_2"
}'`,
    go: `package main

import (
  "bytes"
  "encoding/json"
  "fmt"
  "net/http"
)

type runJobBody struct {
  FileID         string \`json:"file_id"\`
  Callback       string \`json:"callback"\`
  SeparationType string \`json:"separation_type"\`
}

type responseError struct {
  Kind     string \`json:"kind"\`
  Message  string \`json:"message"\`
}

type runJobResponse struct {
  ID string \`json:"id"\`
}

func main() {
  url := "https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/start"
  apiKey := "YOUR-API-KEY-HERE"
  fileID := "TODO: get the file_id using upload flow"
  data, errMarshal := json.Marshal(runJobBody{
    FileID:         fileID,
    Callback:       "",
    SeparationType: "split_2", // can be split_4 or split_6
  })
  if errMarshal != nil {
    fmt.Println("Failed to encode body:", errMarshal.Error())
    return
  }

  req, err := http.NewRequest("POST", url, bytes.NewBuffer(data))
  if err != nil {
    fmt.Println("Failed to init request:", err.Error())
    return
  }

  req.Header.Add("accept", "application/json")
  req.Header.Add("content-type", "application/json")
  req.Header.Add("api-key", apiKey)

  resp, errCall := http.DefaultClient.Do(req)
  if errCall != nil {
    fmt.Println("Failed to execute request:", err)
    return
  }

  defer resp.Body.Close()

  if resp.StatusCode != http.StatusOK {
    var respErr responseError

    if errDecode := json.NewDecoder(resp.Body).Decode(&respErr); errDecode != nil {
      fmt.Println("Failed to decode response:", errDecode)
    } else {
      fmt.Printf("Request failed with status code: %d | kind: %s | message: %s\\n", resp.StatusCode, respErr.Kind, respErr.Message)
    }

    return
  }

  var result runJobResponse
  if errDecode := json.NewDecoder(resp.Body).Decode(&result); errDecode != nil {
    fmt.Println("Failed to decode response:", errDecode.Error())
    return
  }

  fmt.Println("Job started successfully.")
}`,
    javascript: `const fetch = require('node-fetch');

const url = 'https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/start';
const apiKey = 'YOUR-API-KEY-HERE';
const fileID = 'TODO: get the file_id using upload flow'
const data = {
  file_id: fileID,
  callback: '',
  separation_type: 'split_2' // can be split_4 or split_6
};

const headers = {
  accept: 'application/json',
  'content-type': 'application/json',
  'api-key': apiKey
};

fetch(url, {
  method: 'POST',
  headers: headers,
  body: JSON.stringify(data)
})
  .then(response => {
    if (response.status === 200) {
      console.log('Job started successfully.');
    } else {
      console.log(\`Request failed with status code: \\\${response.status}\`);
    }
  })
  .catch(error => {
    console.error('Error making the request:', error);
  });
  `,
    kotlin: `import khttp.post
import org.json.JSONObject

fun main() {
    val url = "https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/start"
    val apiKey = "YOUR-API-KEY-HERE"
    val fileID = "TODO: get the file_id using upload flow"
    val data = mapOf(
        "file_id" to fileID,
        "callback" to "",
        "separation_type" to "split_2" // can be split_4 or split_6
    )

    val headers = mapOf(
        "accept" to "application/json",
        "content-type" to "application/json",
        "api-key" to apiKey
    )

    val response = post(url, headers = headers, json = data)

    if (response.statusCode == 200) {
        println("Job started successfully.")
    } else {
        println("Request failed with status code: \\\${response.statusCode}")
    }
}
  `,
    php: `<?php

$apiUrl = "https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/start";
$apiKey = "YOUR-API-KEY-HERE";
$fileID = "TODO: get the file_id using upload flow";

$data = json_encode([
    "file_id" => $fileID,
    "callback" => "",
    "separation_type" => "split_2" // can be split_4 or split_6
]);

$ch = curl_init($apiUrl);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);

// Set the HTTP headers
$headers = [
    "accept: application/json",
    "content-type: application/json",
    "api-key: " . $apiKey,
];
curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);

// Set the request method to POST
curl_setopt($ch, CURLOPT_POST, true);

// Set the POST data
curl_setopt($ch, CURLOPT_POSTFIELDS, $data);

$response = curl_exec($ch);

if ($response === false) {
    echo 'cURL Error: ' . curl_error($ch);
} else {
    $httpCode = curl_getinfo($ch, CURLINFO_HTTP_CODE);

    if ($httpCode === 200) {
        echo 'Job started successfully.';
    } else {
        echo "Request failed with status code: $httpCode";
    }
}

curl_close($ch);
?>
  `,
    python: `import requests
import json

url = "https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/start"
api_key = "YOUR-API-KEY-HERE"
file_id = "TODO: get the file_id using upload flow"
data = {
    "file_id": "xxxxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxx",
    "callback": "",
    "separation_type": "split_2" # can be split_4 or split_6
}

headers = {
    "accept": "application/json",
    "content-type": "application/json",
    "api-key": api_key
}

response = requests.post(url, headers=headers, data=json.dumps(data))

if response.status_code == 200:
    print("Job started successfully.")
else:
    print(f"Request failed with status code: {response.status_code}")
  `,
    swift: `import Foundation

let apiUrl = "https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/start"
let apiKey = "YOUR-API-KEY-HERE"
let file_id = "TODO: get the file_id using upload flow"
let data = [
    "file_id": file_id,
    "callback": "",
    "separation_type": "split_2" // can be split_4 or split_6
]

if let url = URL(string: apiUrl) {
    var request = URLRequest(url: url)
    request.httpMethod = "POST"
    request.setValue("application/json", forHTTPHeaderField: "accept")
    request.setValue("application/json", forHTTPHeaderField: "content-type")
    request.setValue(apiKey, forHTTPHeaderField: "api-key")
    request.httpBody = try? JSONSerialization.data(withJSONObject: data)

    let task = URLSession.shared.dataTask(with: request) { (data, response, error) in
        if let error = error {
            print("Error making the request: \\(error)")
        } else if let httpResponse = response as? HTTPURLResponse {
            if httpResponse.statusCode == 200 {
                print("Job started successfully.")
            } else {
                print("Request failed with status code: \\(httpResponse.statusCode)")
            }
        }
    }
    task.resume()
} else {
    print("Invalid URL")
}`,
  },
  requestUpload: {
    bash: `curl -X POST "https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/upload-file" \\
-H "accept: application/json" \\
-H "content-type: application/json" \\
-H "api-key: YOUR-API-KEY-HERE" \\
-d '{"name":"my_song","mime_type":"audio/mp3"}'`,
    go: `package main

import (
  "bytes"
  "encoding/json"
  "net/http"
)

type getUploadURLBody struct {
  Name     string \`json:"name"\`
  MimeType string \`json:"mime_type"\`
}

type responseError struct {
  Kind     string \`json:"kind"\`
  Message  string \`json:"message"\`
}

type responseSuccess struct {
  FileID     string \`json:"file_id"\`
  UploadURL  string \`json:"upload_url"\`
}

func main() {
  url := "https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/upload-file"
  apiKey := "YOUR-API-KEY-HERE"
  data, errJson := json.Marshal(getUploadURLBody{
    Name:     "my_song.wav",
    MimeType: "audio/x-wav",
  })

  if errJson != nil {
    // TODO : handle error
    return
  }

  req, errRequest := http.NewRequest("POST", url, bytes.NewBuffer(data))
  if errRequest != nil {
    // TODO : handle error
    return
  }

  req.Header.Add("content-type", "application/json")
  req.Header.Add("accept", "application/json")
  req.Header.Add("api-key", apiKey)

  resp, err := http.DefaultClient.Do(req)
  if err != nil {
    // TODO : handle error
    return
  }

  defer resp.Body.Close()

  response, errRead := io.ReadAll(resp.Body)
  if errRead != nil {
    // TODO : handle error
    return
  }

  if resp.StatusCode != http.StatusOK {
    var respErr responseError

    if errResp := json.Unmarshal(response, &respErr); errResp != nil {
      fmt.Println("Failed to decode response:", errResp.Error())
    } else {
      fmt.Printf("Request failed with status code: %d | kind: %s | message: %s\\n", resp.StatusCode, respErr.Kind, respErr.Message)
    }
  } else {
    var respSuccess responseSuccess

    if errResp := json.Unmarshal(response, &respSuccess); errResp != nil {
      fmt.Println("Failed to decode response:", errResp.Error())
    }
  }

  return
}`,
    javascript: `const fetch = require('node-fetch');

const url = 'https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/upload-file';
const apiKey = 'YOUR-API-KEY-HERE';
const data = {
  name: 'my_song',
  mime_type: 'audio/mp3'
};

const headers = {
  accept: 'application/json',
  'content-type': 'application/json',
  'api-key': apiKey
};

fetch(url, {
  method: 'POST',
  headers: headers,
  body: JSON.stringify(data)
})
  .then(response => {
    if (response.status === 200) {
      console.log('File uploaded successfully.');
    } else {
      console.log(\`Request failed with status code: \\\${response.status}\`);
    }
  })
  .catch(error => {
    console.error('Error making the request:', error);
  });`,
    kotlin: `import khttp.post
import org.json.JSONObject

fun main() {
    val url = "https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/upload-file"
    val apiKey = "YOUR-API-KEY-HERE"
    val data = mapOf("name" to "my_song", "mime_type" to "audio/mp3")

    val headers = mapOf(
        "accept" to "application/json",
        "content-type" to "application/json",
        "api-key" to apiKey
    )

    val response = post(url, headers = headers, json = data)

    if (response.statusCode == 200) {
        println("File uploaded successfully.")
    } else {
        println("Request failed with status code: \\\${response.statusCode}")
    }
}`,
    php: `<?php

$apiUrl = "https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/upload-file";
$apiKey = "YOUR-API-KEY-HERE";
$data = json_encode([
    "name" => "my_song",
    "mime_type" => "audio/mp3"
]);

$ch = curl_init($apiUrl);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);

// Set the HTTP headers
$headers = [
    "accept: application/json",
    "content-type: application/json",
    "api-key: " . $apiKey,
];
curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);

// Set the request method to POST
curl_setopt($ch, CURLOPT_POST, true);

// Set the POST data
curl_setopt($ch, CURLOPT_POSTFIELDS, $data);

$response = curl_exec($ch);

if ($response === false) {
    echo 'cURL Error: ' . curl_error($ch);
} else {
    $httpCode = curl_getinfo($ch, CURLINFO_HTTP_CODE);

    if ($httpCode === 200) {
        echo 'File uploaded successfully.';
    } else {
        echo "Request failed with status code: $httpCode";
    }
}

curl_close($ch);
?>
    `,
    python: `import requests
import json

url = "https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/upload-file"
api_key = "YOUR-API-KEY-HERE"
data = {
    "name": "my_song",
    "mime_type": "audio/mp3"
}

headers = {
    "accept": "application/json",
    "content-type": "application/json",
    "api-key": api_key
}

response = requests.post(url, headers=headers, data=json.dumps(data))

if response.status_code == 200:
    print("File uploaded successfully.")
else:
    print(f"Request failed with status code: {response.status_code}")
    `,
    swift: `import Foundation

let apiUrl = "https://prod.api.mwm-tech-platform.mwmwebapis.com/v1/jobs/stems/upload-file"
let apiKey = "YOUR-API-KEY-HERE"
let data = ["name": "my_song", "mime_type": "audio/mp3"]

if let url = URL(string: apiUrl), let jsonBody = try? JSONSerialization.data(withJSONObject: data) {
    var request = URLRequest(url: url)
    request.httpMethod = "POST"
    request.setValue("application/json", forHTTPHeaderField: "accept")
    request.setValue("application/json", forHTTPHeaderField: "content-type")
    request.setValue(apiKey, forHTTPHeaderField: "api-key")
    request.httpBody = jsonBody

    let task = URLSession.shared.dataTask(with: request) { (data, response, error) in
        if let error = error {
            print("Error making the request: \\(error)")
        } else if let httpResponse = response as? HTTPURLResponse {
            if httpResponse.statusCode == 200 {
                print("File uploaded successfully.")
            } else {
                print("Request failed with status code: \(httpResponse.statusCode)")
            }
        }
    }
    task.resume()
} else {
    print("Invalid URL")
}
    `,
  },
  upload: {
    bash: `curl -X PUT "https://storage.googleapis.com/mwm-files-storage/028d..." \\
--upload-file ./my_song.wav`,
    go: `package main

import (
  "fmt"
  "os"
  "net/http"
)

func main() {
  // Replace with your target URL
  url := "https://storage.googleapis.com/mwm-files-storage/028d..."
  filePath := "./my_song.wav"

  fileContent, err := os.Open(filePath)
  if err != nil {
    fmt.Println("Error opening the file:", err)
    return
  }
  defer fileContent.Close()

  req, err := http.NewRequest("PUT", url, fileContent)
  if err != nil {
    fmt.Println("Error creating request:", err)
    return
  }

  resp, err := http.DefaultClient.Do(req)
  if err != nil {
    fmt.Println("Error making request:", err)
    return
  }
  defer resp.Body.Close()

  if resp.StatusCode == http.StatusNoContent {
    fmt.Println("File uploaded successfully.")
  } else {
    fmt.Printf("Request failed with status: %d\\n", resp.StatusCode)
  }
}`,
    javascript: `const fs = require('fs');
const fetch = require('node-fetch');

// Replace with your target URL
const url = 'https://storage.googleapis.com/mwm-files-storage/028d...';
const file_path = './my_song.wav';

const fileStream = fs.createReadStream(file_path);

fetch(url, {
  method: 'PUT',
  body: fileStream,
})
  .then(response => {
    if (response.status === 204) {
      console.log('File uploaded successfully.');
    } else {
      console.log(\`Request failed with status code: \\\${response.status}\`);
    }
  })
  .catch(error => {
    console.error('Error making the request:', error);
  });`,
    kotlin: `import khttp.put
import java.io.File

fun main() {
    // Replace with your target URL
    val url = "https://storage.googleapis.com/mwm-files-storage/028d..." 
    val filePath = "./my_song.wav"

    val file = File(filePath)

    val response = put(url, data = file)

    if (response.statusCode == 204) {
        println("File uploaded successfully.")
    } else {
        println("Request failed with status code: \\\${response.statusCode}")
    }
}`,
    php: `<?php

// Replace with your target URL
$apiUrl = "https://storage.googleapis.com/mwm-files-storage/028d...";
$filePath = "./my_song.wav";

$file = fopen($filePath, 'r');

$ch = curl_init($apiUrl);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);

curl_setopt($ch, CURLOPT_PUT, true);
curl_setopt($ch, CURLOPT_INFILE, $file);
curl_setopt($ch, CURLOPT_INFILESIZE, filesize($filePath));

$response = curl_exec($ch);

if ($response === false) {
    echo 'cURL Error: ' . curl_error($ch);
} else {
    $httpCode = curl_getinfo($ch, CURLINFO_HTTP_CODE);

    if ($httpCode === 204) {
        echo 'File uploaded successfully.';
    } else {
        echo "Request failed with status code: $httpCode";
    }
}

curl_close($ch);
fclose($file);
?>`,
    python: `import requests

# Replace with your target URL
url = "https://storage.googleapis.com/mwm-files-storage/028d..." 
file_path = "./my_song.wav"

with open(file_path, 'rb') as file:
    response = requests.put(url, data=file)

if response.status_code == 204:
    print("File uploaded successfully.")
else:
    print(f"Request failed with status code: {response.status_code}")
    `,
    swift: `import Foundation

// Replace with your target URL
let apiUrl = "https://storage.googleapis.com/mwm-files-storage/028d..." 
let filePath = "./my_song.wav"

if let url = URL(string: apiUrl), let fileData = try? Data(contentsOf: URL(fileURLWithPath: filePath)) {
    var request = URLRequest(url: url)
    request.httpMethod = "PUT"
    request.httpBody = fileData

    let task = URLSession.shared.dataTask(with: request) { (data, response, error) in
        if let error = error {
            print("Error making the request: \\(error)")
        } else if let httpResponse = response as? HTTPURLResponse {
            if httpResponse.statusCode == 204 {
                print("File uploaded successfully.")
            } else {
                print("Request failed with status code: \\(httpResponse.statusCode)")
            }
        }
    }
    task.resume()
} else {
    print("Invalid URL or file path")
}`,
  },
}
