import axios from 'axios'
import Cookies from 'universal-cookie'

import type { IApiError } from '../types/api'
import type { AxiosRequestConfig, AxiosError } from 'axios'

import { COOKIES } from '~/const'

const cookies = new Cookies()

export const request = async <T>(config: AxiosRequestConfig, withAuth = true): Promise<T> => {
  const authorizationHeader: Record<string, string> = withAuth
    ? { authorization: `Bearer ${cookies.get(COOKIES.TOKENS)?.access_token}` }
    : {}

  const { data } = await axios
    .request<T>({
      method: 'GET',
      ...config,
      headers: {
        ...config.headers,
        ...authorizationHeader,
      },
    })
    .catch((e: AxiosError<IApiError>) => Promise.reject(e))

  return data
}

export function isTokenValid(tokenExpiresAt: number): boolean {
  try {
    const expirationDate = new Date(tokenExpiresAt * 1000).getTime()
    const now = Date.now()

    return expirationDate > now
  } catch {
    return false
  }
}
